<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280" >
    <MainModal
        :main="{ component: 'TariffOrganization', title: 'Тарифи по організації' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_tariff_by_org)"
        :modal="show_tariff_by_org_dialog"
        @updateItemModal="tariffByOrgUpdateItemModal"
    />
    <MainModal
        :main="{ component: 'TariffBuilding', title: 'Тарифи по будинку' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_tariff_by_building)"
        :modal="show_tariff_by_building_dialog"
        @updateItemModal="tariffByBuildingUpdateItemModal"
    />
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Нормативні параметри
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-menu offset-y :close-on-content-click="true" tile class="elevation-0"
                :content-class="'menu-without-border'"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="grey lighten-4"
                icon
                outlined
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <div class="px-2 pt-2">
            <v-btn @click="openTariffByOrgCreateDialog" class="grey lighten-4 mb-3"
                   block style="justify-content: flex-start">
              <v-icon left class="mr-2">mdi-plus</v-icon>
              Тариф по організації (зона дії уся організація)
            </v-btn>
<!--            <MainModal-->
<!--              :main="{ component: 'TariffOrganization', title: 'Тарифи по організації' }"-->
<!--              :button="{ icon: false, color: 'grey lighten-4', buttonIcon: 'mdi-plus', buttonIconClass: 'mr-2',-->
<!--              buttonBlock: true, buttonClass: 'mb-3', buttonIconLeft: true, buttonStyle: 'justify-content: flex-start',-->
<!--              buttonName: 'Тариф по організації (зона дії уся організація)' }"-->
<!--              :item="{ from_flat: true, date_start: current_month }"-->
<!--              @updateItemModal="flatIndicatorUpdateItemModal"-->
<!--          />-->
          </div>
          <div class="px-2 pb-5">
            <v-btn @click="openTariffByBuildingCreateDialog" class="grey lighten-4 mb-3"
                   block style="justify-content: flex-start">
              <v-icon left class="mr-2">mdi-plus</v-icon>
              Тариф по будинку (зона дії увесь будинок)
            </v-btn>
<!--            <MainModal-->
<!--                :main="{ component: 'TariffBuilding', title: 'Тарифи по будинку' }"-->
<!--                :button="{ icon: false, color: 'grey lighten-4', buttonIcon: 'mdi-plus', buttonIconClass: 'mr-2',-->
<!--                buttonBlock: true, buttonIconLeft: true, buttonStyle: 'justify-content: flex-start',-->
<!--                buttonName: 'Тариф по будинку (зона дії увесь будинок)' }"-->
<!--                :item="{ from_flat: true, date_start: current_month, building_id: this.flat.building_id }"-->
<!--                @updateItemModal="flatIndicatorUpdateItemModal"-->
<!--            />-->
          </div>

        </v-menu>
      </v-toolbar>
    </v-card-title>
    <v-data-table
        :headers="houseParameterHeaders"
        :items="flat_all_tariff"
        hide-default-footer
        class="custom-table custom-table-1"
        @click:row="onTariffItemClick"
        :options="{itemsPerPage: 999999}"
        :height="$vuetify.breakpoint.smAndUp ? 'calc(100vh - 320px)' : ''"
    >
      <template v-slot:item.icon="{ item }">
        <v-icon size="26" class="mr-2" :color="getIcon(item.icon, 'color')">
          {{ getIcon(item.icon, 'icon') }}
        </v-icon>
      </template>

      <template v-slot:item.service_name="{ item }">
        <span :class="{'font-weight-medium': item.actual}">
          {{ item.service_name }}
        </span>
      </template>

      <template v-slot:item.tariff_type="{ item }">
        <span :class="{'font-weight-medium': item.actual}">
          {{ item.tariff_type }}
        </span>
      </template>

      <template v-slot:item.tariff_group_type_name="{ item }">
        <span :class="{'font-weight-medium': item.actual}">
          {{ item.tariff_group_type_name }}
        </span>
      </template>

      <template v-slot:item.date_start="{ item }">
        <span :class="{'font-weight-medium': item.actual}">
          {{ item.date_start | formatDate }}
        </span>
      </template>

      <template v-slot:item.date_end="{ item }">
        <span>
          {{ item.date_end | formatDate }}
        </span>
      </template>

      <template v-slot:item.value_dec="{ item }">
        <span :class="{'font-weight-medium': item.actual}">
          {{ item.value_dec | formatNumber('0.000000') }}
        </span>
      </template>

      <template v-slot:item.actual="{ item }">
        <v-simple-checkbox :value="item.actual" color="success" class="mt-0"/>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import MainModal from "@/components/modal/MainModal";
import {mapActions, mapGetters} from "vuex";
import {GET_ALL_FLAT_TARIFF} from "@/store/actions/flat_service";
import {accountIcons} from "@/utils/icons";

export default {
  name: "HWP_TariffForFlat",
  components: { MainModal },
  props: {
    inner_person_hash: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      selected_tariff_by_org: {},
      show_tariff_by_org_dialog: false,
      selected_tariff_by_building: {},
      show_tariff_by_building_dialog: false,
      houseParameterHeaders: [
        { text: '', value: 'icon', width: 26 },
        { text: 'Послуга', value: 'service_name' },
        { text: 'Тип тарифу', value: 'tariff_type'},
        { text: 'Група тарифу', value: 'tariff_group_type_name' },
        { text: 'Початок', value: 'date_start' },
        { text: 'Закінчення', value: 'date_end' },
        { text: 'Тариф', value: 'value_dec', width: 110},
        { text: 'Актуальний', value: 'actual', align: 'center' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      flat_all_tariff: 'getAllFlatTariff',
      flat: 'getFlat',
      settings: 'getGeneralSettings',
      current_month: 'getCurrentMonth'
    }),
  },
  methods: {
    ...mapActions({
      fetchAllFlatTariff: GET_ALL_FLAT_TARIFF
    }),
    getIcon(name, type) {
      const defaultIcon = {icon: 'mdi-text-box-check-outline', color: 'success'}
      const value = accountIcons[name] || defaultIcon
      return value[type]
    },
    tariffByOrgUpdateItemModal(payload) {
      this.show_tariff_by_org_dialog = false
      this.selected_tariff_by_org = {}

      if (payload) {
        if (payload.payload === 'fetch') {
          this.$emit('fetch')
        }
      }
    },
    openTariffByOrgCreateDialog() {
      this.selected_tariff_by_org = {
        from_flat: true,
        date_start: this.current_month
      }
      this.show_tariff_by_org_dialog = true
    },
    tariffByBuildingUpdateItemModal(payload) {
      this.show_tariff_by_building_dialog = false
      this.selected_tariff_by_building = {}

      if (payload) {
        if (payload.payload === 'fetch') {
          this.$emit('fetch')
        }
      }
    },
    openTariffByBuildingCreateDialog() {
      this.selected_tariff_by_building = {
        from_flat: true,
        date_start: this.current_month,
        building_id: this.flat.building_id
      }
      this.show_tariff_by_building_dialog = true
    },
    onTariffItemClick(payload) {
      this.selected_tariff_by_org = {}
      this.selected_tariff_by_building = {}
      this.show_tariff_by_org_dialog = false
      this.show_tariff_by_building_dialog = false

      const local_payload = JSON.parse(JSON.stringify(payload))

      if (payload.type === 'organization') {
        local_payload.from_flat = true
        this.selected_tariff_by_org = local_payload
        this.show_tariff_by_org_dialog = true
      }
      if (payload.type === 'building') {
        local_payload.from_flat = true
        this.selected_tariff_by_building = local_payload
        this.show_tariff_by_building_dialog = true
      }
    },
  },
  mounted() {
    const person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash
    if (person_hash) {
      this.fetchAllFlatTariff(person_hash)
    }
  },
  watch: {
    person_hash: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.fetchAllFlatTariff(payload)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .menu-without-border {
    box-shadow: none;
  }

  //.custom-table-1 {
  //  table {
  //    height: calc(100vh - 320px);
  //    overflow: auto;
  //  }
  //}

</style>